<template>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2"></div>
                        <b-alert variant="success" :show="$route.params.status == '1'">
                            <p class="text-center" style="font-size: 45px;"><i class="ri-checkbox-circle-line"></i></p>
                            <p class="text-center" style="font-size:22px;">{{ $route.params.message ? $route.params.message : $t('unihall_success_pay') }}</p>
                        </b-alert>
                        <b-alert variant="danger" :show="$route.params.status == '0'">
                            <p class="text-center" style="font-size: 45px;"><i class="ri-error-warning-line"></i></i></p>
                            <p class="text-center" style="font-size:22px;">{{ $route.params.message ? $route.params.message : $t('unihall_unsuccess_pay') }}</p>
                        </b-alert>
                        <b-alert variant="info" show>{{$t('close_popup_info')}}</b-alert>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>

export default {
    components: {

    },
    data() {
        return {

        }
    },

    methods: {

    },
    created() {

    }
}
</script>
<style lang="">

</style>
